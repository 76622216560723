
















import { Component, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: mapState(['dialogMessage', 'dialogButton', 'dialogVisible'])
})
export default class MessageDialog extends Vue {
  private dialogMessage!: string
  private dialogButton!: string
  private dialogVisible!: boolean

  get show(): boolean {
    if (this.dialogVisible != null) {
      return this.dialogVisible
    }
    return false
  }

  set show(value: boolean) {
    if (!value) {
      this.$store.dispatch('showMessageDialog', { message: '', button: '', show: false })
    }
  }

  message(): string {
    if (this.dialogMessage != null) {
      return this.dialogMessage
    }
    return ''
  }

  btnText(): string {
    if (this.dialogButton != null) {
      return this.dialogButton
    }
    return ''
  }

  close() {
    this.show = false
  }
}
