














import { Component, Prop, Vue } from 'vue-property-decorator'
import { AttributionV2 } from '@/types/attributions'

@Component
export default class DeleteAttributionDialog extends Vue {
  @Prop() onItemDeleted!: Function
  public item: AttributionV2 | null = null
  public show: boolean = false

  get categoryList() {
    return this.$store.state.categories
  }

  get categoryMap() {
    return this.$store.state.categoryMap
  }

  created() {}

  open(item: AttributionV2) {
    this.item = item
    this.show = true
  }

  async deleteItemConfirm() {
    await this.$store.dispatch('deleteAddress', {item: this.item})
    this.close()
    if (this.onItemDeleted != null) {
      this.onItemDeleted()
    }
  }

  close() {
    this.item = null
    this.show = false
  }
}
