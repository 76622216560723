var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"dense-width",attrs:{"headers":_vm.headers,"items":_vm.itemsList,"loading":_vm.isLoading,"item-key":"index","loading-text":"Loading...","expanded":_vm.expanded,"footer-props":{
      itemsPerPageOptions: _vm.itemsPerPage,
      showCurrentPage: true,
      showFirstLastPage: true
    },"server-items-length":_vm.totalItems,"dense":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":_vm.pageUpdated,"update:items-per-page":_vm.itemsPerPageUpdated,"update:sort-by":_vm.onSortByUpdated,"update:sort-desc":_vm.onSortDescUpdated},scopedSlots:_vm._u([{key:"item.video",fn:function(ref){
    var item = ref.item;
return [_c('a',{attrs:{"href":_vm.ytLink(item.videoId)}},[_vm._v(_vm._s(_vm.clipText(item.videoTitle, 50)))]),_c('span',{staticStyle:{"white-space":"pre"}},[_vm._v(_vm._s(" "))]),_c('v-btn',{staticStyle:{"float":"right"},attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.copyLink(item.videoId)}}},[_vm._v(" Copy Link ")])]}},{key:"item.videoLength",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.status !== 'downloading' ? _vm.toMMSS(item.videoLength) : 'N/A')+" ")]}},{key:"item.firstAppeared",fn:function(ref){
    var item = ref.item;
return [(item.status === 'complete')?_c('span',[(Object.keys(item.detectedAddresses).length === 0)?_c('span',[_vm._v(" None ")]):_c('span',_vm._l((item.sortedAddresses),function(addr,index){return _c('a',{key:addr+index,attrs:{"href":_vm.ytLink(item.videoId, item.detectedAddresses[addr])}},[_vm._v(" "+_vm._s(_vm.toMMSS(item.detectedAddresses[addr]))),_c('br')])}),0)]):_c('span',[_vm._v("N/A")])]}},{key:"item.detectedAddresses",fn:function(ref){
    var item = ref.item;
return [(item.status === 'complete')?_c('span',[(Object.keys(item.detectedAddresses).length === 0)?_c('span',[_vm._v(" None ")]):_c('span',_vm._l((item.sortedAddresses),function(addr,index){return _c('span',{key:addr + index},[_c('a',{on:{"click":function($event){return _vm.onAddressClicked(item.videoId, addr)}}},[_vm._v(" "+_vm._s(_vm.clipText(addr, 40))+" ")]),_c('input',{staticStyle:{"float":"right"},attrs:{"type":"checkbox"},on:{"change":function (event) { return _vm.checkboxToggled(event, addr, item); }}}),_c('br')])}),0)]):_c('span',[_vm._v("N/A")])]}},{key:"item.author",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.author)+" ")]}},{key:"item.uploadDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.uploadDate)+" ")]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }