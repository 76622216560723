












































































  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { AttributionV3 } from '@/types/attributions'
  import Multiselect from 'vue-multiselect'
import { AttributionWithIds } from '@/store'
  
@Component({
  components: {
    Multiselect
  }
})
export default class MergeAttributionsDialog extends Vue {
  @Prop() title!: string
  @Prop() mode!: string
  @Prop() onItemAdded!: Function
  @Prop() onItemUpdated!: Function
  public mainAttribution: AttributionV3 | null = null
  public attributionsToMerge: AttributionV3[] = []
  public show: boolean = false
  public first: boolean = false

  get attributionList(): AttributionWithIds[] {
    return this.$store.state.attributions
  }

  get isLoading() {
    return this.$store.state.attributions.length === 0
  }
  
  created() {}

  firstMerge() {
    this.first = true
  }

  confirm() {

  }

  merge() {
    this.$store.dispatch('mergeAttributions', {mainAttribution: this.mainAttribution, attributionsToMerge: this.attributionsToMerge})
    this.close()
  }

  open(item: AttributionV3) {
    this.mainAttribution = item
    this.show = true
  }

  close() {
    this.mainAttribution = null
    this.show = false
    this.$store.dispatch('getAttributions', {page: 0, count: 5000, query: {}})
  }
}
