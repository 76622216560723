import { StringMap } from '../types/attributions'

export function chunkArray(data: any[], chunkSize: number): any[][] {
  const chunkCount = Math.ceil(data.length / chunkSize)
  const chunks: any[][] = []
  for (let i = 0; i < chunkCount; i++) {
    const start = i * chunkSize
    const end = (i + 1) * chunkSize
    const chunk = data.slice(start, end)
    chunks.push(chunk)
  }
  return chunks
}

export function jsonToCSV(
  data: StringMap[],
  internalData: boolean,
  delimiter: string = ',',
  qualifier: string = '"'
): string {
  const keys = Object.keys(data[0])
  const needQualifiers = ['network', 'attribution', 'category']
  // remove internal columns
  const internalKeys = ['user', 'timeAdded']
  if (!internalData) {
    for (const key of internalKeys) {
      keys.splice(keys.indexOf(key), 1)
    }
  }
  const csv: string[] = []
  // header
  csv.push(keys.join(delimiter))
  // rows
  for (const d of data) {
    const line: string[] = []
    for (const key of keys) {
      const value = d[key]
      if (Array.isArray(value) && value.length > 1) {
        line.push(`"${value.join(delimiter)}"`)
      } else {
        line.push(value.toString())
      }
    }
    csv.push(line.join(delimiter))
  }
  return csv.join('\n')
}

export function youtubeJsonToCSV(data: any, delimiter: string = ',', qualifier: string = '"') {
  const keys = ['address', 'networks', 'attributions', 'categories', 'source', 'note']
  const csv: string[] = []

  // header
  csv.push(keys.join(delimiter))

  // rows
  Object.entries(data).forEach(([addr, scan]: [string, any]) => {
    const line: string[] = []
    line.push(addr)
    line.push('NETWORKS')
    line.push('ATTRIBUTIONS')
    line.push('CATEGORIES')
    line.push(`https://www.youtube.com/watch?v=${scan.videoId}`)
    line.push('')

    csv.push(line.join(delimiter))
  })

  return csv.join('\n')
}
