










import { Component, Vue } from 'vue-property-decorator'
import Menu from '@/components/Menu.vue'

@Component({
  components: {
    Menu
  }
})
export default class Dashboard extends Vue {
  setUpDocument(container: Element) {
    container.addEventListener('contextmenu', (e: any) => {
      e.preventDefault()
      e.stopPropagation()
    })
  }

  mounted() {
    this.setUpDocument(this.$refs.dash as Element)
  }
}
