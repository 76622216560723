












































import { Component, Prop, Vue } from 'vue-property-decorator'

export interface Header {
  text: string
  align?: string
  filterable?: boolean
  value: string
}

@Component
export default class Table extends Vue {
  public search: string = ''
  @Prop() searchable!: boolean
  @Prop() searchLabel!: string
  @Prop() label!: string
  @Prop() headers!: Header[]
  @Prop() items!: any[]
  @Prop() onItemClick!: Function
  @Prop() onSearchClick!: Function
  @Prop() itemKey!: string
  @Prop() loading!: boolean
  // public isLoading: boolean = false
  public itemsPerPage: number[] = [
    10,20,50,100,-1
  ]

  created() {
    
  }

  get isLoading(): boolean {
    return !!this.loading
  }

  get title() {
    return (this.label == null) ? '' : this.label
  }

  get isSearchable() {
    return (this.searchable == null) ? false : true
  }

  get searchLabelText() {
    return (this.searchLabel == null) ? 'Search' : this.searchLabel
  }

  clicked(item: any, row: any) {
    if (this.onItemClick != null) {
      this.onItemClick(item)
    } 
  }

}
