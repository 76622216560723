







































































import { Component, Vue } from 'vue-property-decorator'
import Dialog from '@/components/Dialog.vue'
import SignInForm from '@/components/SignInForm.vue'
import MessageDialog from '@/components/MessageDialog.vue'
import { DialogConfig } from './types/general'

@Component({
  components: {
    Dialog,
    MessageDialog
  }
})
export default class App extends Vue {
  public signInDialog: DialogConfig<typeof SignInForm> = {
    button: {
      icon: 'mdi-account-circle'
    },
    dialog: {
      title: 'Sign In',
      action: 'Sign In'
    },
    content: SignInForm
  }
  get adminSignedIn() {
    return this.$store.state.signedIn && this.$store.state.username === 'admin'
  }
  get signedIn() {
    return this.$store.state.signedIn
  }
  created() {
    this.checkSignedIn()
  }

  private checkSignedIn() {
    this.$store.dispatch('checkAuth')
  }

  public signOut() {
    this.$store.dispatch('signOut')
  }
}
