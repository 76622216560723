











































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import YouTubeScansTable, { Header } from '@/components/YouTubeScansTable.vue'
import { downloadYoutubeCSV } from '@/utils/download'

@Component({
  components: {
    YouTubeScansTable
  }
})
export default class YouTubeScans extends Vue {
  public headers: Header[] = [
    {text: 'Video', filterable: true, value: 'video'},
    {text: 'Length', filterable: true, value: 'videoLength'},
    {text: 'Appears at', filterable: true, value: 'firstAppeared'},
    {text: 'Addresses', filterable: true, value: 'detectedAddresses'},
    {text: 'User', filterable: true, value: 'author'},
    {text: 'Upload Date', filterable: true, value: 'uploadDate'},
    {text: 'Status', filterable: true, value: 'status'},
  ]

  public filename = 'youtube-scans'
  public searchText = ''
  private page: number = 0
  public itemsPerPage = 100
  private sortBy: string = 'createdAt'
  private sortDesc: boolean = false
  public selected = "exchange name"
  public videosPerQuery = 10
  public tutorialChecked = false
  public depositChecked = false
  public withdrawalChecked = false
  public yearsChecked = false
  public yearsStart = 2016
  public yearsEnd = 2018
  private showOnlyMyScans = false
  public checkedAddresses: {[key: string]: string} = {}
  private autoRefresh = false
  private dataUpdating = false

  get items() {
    return this.$store.getters.youtubeScans
  }

  get viewCount() {
    return this.$store.state.youtubeScanCount
  }

  created() {
    this.updateData()
  }

  async updateData() {
    this.dataUpdating = true
    await Promise.all([
      this.getYouTubeScansCount(),
      this.getYouTubeScans()
    ])
    this.dataUpdating = false
  }

  async toggleAutoRefresh() {
    this.autoRefresh = !this.autoRefresh
    console.log('auto refresh started')
    while (this.autoRefresh) {
      if (!this.dataUpdating) {
        await this.updateData()
        await new Promise(resolve => setTimeout(resolve, 3000))
      } else {
        console.log('there is already an instance of auto refresh running')
        break
      }
    }
    console.log('auto refresh stopped')
  }

  async getYouTubeScansCount() {
    await this.$store.dispatch('getYoutubeScansCount', {showOnlyMyScans: this.showOnlyMyScans})
  }

  @Watch('$store.state.youtubeScanCount')
  async getYouTubeScans() {
    await this.$store.dispatch('getYoutubeScans', {page: this.page, count: this.itemsPerPage, sortBy: this.sortBy, sortDesc: this.sortDesc, showOnlyMyScans: this.showOnlyMyScans})
  }

  async pageUpdated(page: number) {
    this.page = page
    await this.getYouTubeScans()
  }

  async itemsPerPageUpdated(count: number) {
    this.itemsPerPage = count
    await this.getYouTubeScans()
  }

  async sortByUpdated(key: string) {
    this.sortBy = key ?? 'createdAt'
    await this.getYouTubeScans()
  }

  async sortDescUpdated(desc: boolean) {
    this.sortDesc = desc ?? false
    await this.getYouTubeScans()
  }

  async showOnlyMyScansUpdated(checked: boolean) {
    this.showOnlyMyScans = checked
    await this.getYouTubeScans()
  }

  async submitQuery() {
    if (this.selected === 'single video') {
      await this.$store.dispatch('dispatchLambda', {link: this.searchText})
    }
    else if (this.selected === 'search query') {
      await this.$store.dispatch('dispatchQuery', {query: this.searchText, numVideos: this.videosPerQuery})
    }
    else {
      this.generateQueries()
    }
  }

  generateQueries() {
    let queries = [this.searchText]
    if (this.depositChecked || this.withdrawalChecked) {
      const d = this.depositChecked ? queries.map(query => `${query} deposit`) : []
      const w = this.withdrawalChecked ? queries.map(query => `${query} withdrawal`) : []
      queries = queries.concat(d, w)
    }
    if (this.tutorialChecked) {
      queries = queries.concat(queries.map(query => `${query} tutorial`))
    }
    if (this.yearsChecked) {
      const years = new Array(this.yearsEnd - this.yearsStart + 1).fill(0).map((_, i) => this.yearsStart + i)
      queries = years.map(year => queries.map(query => `${query} before:${year}-12-31 after:${year}-01-01`)).flat()
    }

    this.$store.dispatch('dispatchQueries', {queries: queries, videosPerQuery: this.videosPerQuery})
  }
  
  downloadFile() {
    downloadYoutubeCSV(this.checkedAddresses, this.filename)
  }

  async downloadFrame(videoId: string, addr: string) {
    await this.$store.dispatch('downloadFrame', {videoId, addr})
  }
}
