































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import AddEditAttributionDialog from '@/components/AddEditAttributionDialog.vue'
import DeleteAttributionDialog from '@/components/DeleteAttributionDialog.vue'
import { AttributionV2, AttributionV3, Category, IntermediateAttribution } from '../types/attributions'
import { titleCase } from '../utils/general'
import { mapState } from 'vuex'

export interface Header {
  text: string
  align?: string
  filterable?: boolean
  value: string
  optional?: boolean
  validate?: boolean
  sortable?: boolean
}

@Component({
  components: {
    AddEditAttributionDialog,
    DeleteAttributionDialog
  },
  computed: mapState(['categoryMap', 'localAttributionMap'])
})
export default class AttributionsTable extends Vue {
  @Prop() headers!: Header[]
  @Prop() items!: any[]
  @Prop() itemsCount!: number
  @Prop() resetPageIndicator!: boolean
  @Prop() onPageUpdated!: Function
  @Prop() onItemsPerPageUpdated!: Function
  @Prop() onItemUpdated!: Function
  @Prop() onItemDeleted!: Function
  @Prop() onItemAdded!: Function
  @Prop() modify!: boolean
  @Prop() showAdd!: boolean
  @Prop() dataSource!: string
  @Prop() isLoading!: boolean
  public page: number = 1
  public itemsPerPage: number[] = [
    100,200,-1
  ]
  public expanded: any[] = []

  public categoryMap!: Map<number, Category> | null
  public localAttributionMap!: Map<number, AttributionV3>

  get itemsList() {
    return this.items.map((item, index) => {
      const { user, ...remainder } = item
      return {
        index,
        user: (user != null) ? titleCase(user) : '',
        modify: true, // value doesn't matter
        ...remainder
      }
    })
  }

  get totalItems() {
    return (this.itemsCount == null) ? undefined : this.itemsCount
  }

  get filteredHeaders() {
    if (this.modify) {
      return this.headers
    }
    return this.headers.filter(h => h.value !== 'modify')
  }

  get source() {
    return this.dataSource || 'internal'
  }

  resolveCategories(categories: number[] | number, map: Map<string, Category>) {
    const resolved: string[] = []
    if (Array.isArray(categories) && map != null) {
      for (const cat of categories) {
        const category = map.get(`${cat}`)
        if (category != null) {
          resolved.push(category.name)
        } else {
          console.error(`couldn't find category ${cat} in category list`)
        }
      }
    } else if (map != null) {
      const category = map.get(`${categories}`)
      if (category != null) {
        resolved.push(category.name)
      } else {
        console.error(`couldn't find category ${categories} in category list`)
      }
    }
    return resolved.join(', ')
  }

  categoryName(attributionId?: string | number, categoryId?: number) {
    const defaultName = 'UNKNOWN'
    if (this.categoryMap == null) {
      console.warn('categoryMap is null')
      return defaultName
    }
    if (attributionId != null) {
      attributionId = typeof attributionId === 'string' ? parseInt(attributionId, 10) : attributionId
      if (this.localAttributionMap) {
        const attribution: AttributionV3 | undefined = this.localAttributionMap.get(attributionId)
        if (attribution) {
          const category: Category | undefined = this.categoryMap.get(attribution.categoryId)
          if (category) {
            return category.name
          }
        }
        return defaultName
      } else {
        return categoryId
      }
    } else if (categoryId != null) {
      const category = this.categoryMap.get(categoryId)
      return category != null ? category.name : defaultName
    } else {
      return defaultName
    }
  }

  attributionName(attribution: string, attributionId: number) {
    if (attribution) {
      return attribution
    } else {
    if (this.localAttributionMap) {
        const attribution: AttributionV3 | undefined = this.localAttributionMap.get(attributionId)
        if (attribution) {
          return attribution.name
        } else {
          return 'N/A'
        }
      } else {
        return 'N/A'
      }
    }
  }

  editItem(item: AttributionV2) {
    (<AddEditAttributionDialog>this.$refs.editDialog).open({ item })
  }

  addItem() {
    (<AddEditAttributionDialog>this.$refs.addDialog).open({ user: this.$store.state.username })
  }

  deleteItem(item: AttributionV2) {
    (<DeleteAttributionDialog>this.$refs.deleteDialog).open(item)
  }

  sameUser(user: string): boolean {
    return (user != null && user.toLowerCase() === this.$store.state.username)
      || this.$store.state.isAdmin
  }

  pageUpdated(page: number) {
    this.page = page
    if (this.onPageUpdated != null) {
      this.onPageUpdated(page - 1) // zero-based page index for query
    }
  }

  itemsPerPageUpdated(count: number) {
    if (this.onItemsPerPageUpdated != null) {
      this.onItemsPerPageUpdated(count)
    }
  }

  @Watch('resetPageIndicator')
  resetPage() {
    this.pageUpdated(1)
  }
}
