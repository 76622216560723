var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[(_vm.showAdd)?_c('v-btn',{staticClass:"ml-6 mt-4",attrs:{"fab":"","dark":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.addItem()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"dense-width",attrs:{"headers":_vm.filteredHeaders,"items":_vm.itemsList,"loading":_vm.isLoading,"item-key":"index","loading-text":"Loading...","single-expand":"","show-expand":"","expanded":_vm.expanded,"page":_vm.page,"footer-props":{
      itemsPerPageOptions: _vm.itemsPerPage,
      showCurrentPage: true,
      showFirstLastPage: true
    },"server-items-length":_vm.totalItems,"dense":""},on:{"update:expanded":function($event){_vm.expanded=$event},"update:page":_vm.pageUpdated,"update:items-per-page":_vm.itemsPerPageUpdated},scopedSlots:_vm._u([{key:"item.modify",fn:function(ref){
    var item = ref.item;
return [(_vm.sameUser(item.user))?[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]:_vm._e()]}},{key:"item.source",fn:function(ref){
    var value = ref.value;
return [(value != null && value.startsWith('http'))?_c('a',{attrs:{"href":value,"title":value}},[_vm._v("Link")]):_c('span',[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.timeAdded",fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s(new Date(value).toLocaleString())+" ")]}},{key:"item.updated",fn:function(ref){
    var value = ref.value;
return [_vm._v(" "+_vm._s((value) ? new Date(value).toLocaleString() : '-')+" ")]}},{key:"item.category",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.categoryName(item.attributionId, item.categoryId))+" ")]}},{key:"item.attribution",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.attributionName(item.attribution, item.attributionId))+" ")]}},{key:"item.validated",fn:function(ref){
    var value = ref.value;
return [(value)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-bold")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-thick")])]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Note: "+_vm._s(item.note || '')+" ")])]}}],null,true)}),_c('AddEditAttributionDialog',{ref:"editDialog",attrs:{"title":'Edit attribution',"mode":'edit',"onItemUpdated":_vm.onItemUpdated}}),_c('AddEditAttributionDialog',{ref:"addDialog",attrs:{"title":'Add attribution',"mode":'add',"onItemAdded":_vm.onItemAdded}}),_c('DeleteAttributionDialog',{ref:"deleteDialog",attrs:{"onItemDeleted":_vm.onItemDeleted}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }