







































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { copyToClipboard } from '../utils/general'

export interface Header {
  text: string
  align?: string
  filterable?: boolean
  value: string
  optional?: boolean
  validate?: boolean
}

@Component({
  components: {
  }
})
export default class YouTubeScansTable extends Vue {
  @Prop() headers!: Header[]
  @Prop() items!: any[]
  @Prop() itemsCount!: number
  @Prop() onPageUpdated!: Function
  @Prop() onItemsPerPageUpdated!: Function
  @Prop() onSortByUpdated!: Function
  @Prop() onSortDescUpdated!: Function
  @Prop() onItemUpdated!: Function
  @Prop() onItemDeleted!: Function
  @Prop() onItemAdded!: Function
  @Prop() showAdd!: boolean
  @Prop() checkedAddresses!: { [key: string]: any }
  @Prop() downloadFrame!: Function
  public isLoading: boolean = false
  public itemsPerPage: number[] = [
    100,200,-1
  ]
  public expanded: any[] = []

  get itemsList() {
    return this.items.map(item => ({
      ...item,
      sortedAddresses: Object.keys(item.detectedAddresses).sort((a, b) => item.detectedAddresses[a] - item.detectedAddresses[b])
    }))
  }

  get totalItems() {
    return (this.itemsCount == null) ? undefined : this.itemsCount
  }

  created() {}

  ytLink(videoId: string, time?: number) {
    if (time === undefined) {
      return `https://youtube.com/watch?v=${videoId}`
    } else {
      return `https://youtube.com/watch?v=${videoId}&t=${Math.floor(time / 60)}m${time % 60}s`
    }
  }

  clipText(text: string, length: number) {
    if (text.length > length) {
      return text.slice(0, length) + '...'
    }
    return text
  }

  checkboxToggled(event: any, addr: string, item: any) {
    if (event.target.checked) {
      this.checkedAddresses[addr] = item
    } else {
      delete this.checkedAddresses[addr]
    }
  }

  copyLink(videoId: string) {
    copyToClipboard(this.ytLink(videoId))
  }

  toMMSS(seconds: number) {
    const minutes = Math.floor(seconds / 60)
    const secondsLeft = seconds % 60
    return `${minutes}:${secondsLeft < 10 ? '0' : ''}${secondsLeft}`
  }

  toRange(ranges: any[], status: string) {
    if (status !== 'complete') {
      return 'N/A'
    } else if (ranges.length === 0) {
      return 'None'
    }

    return ranges.map((range: any) => {
      const { start, end } = range
      return `${this.toMMSS(start)} - ${this.toMMSS(end)}`
    }).join('\n')
  }

  toAddressesList(addresses: any[], status: string) {
    if (status !== 'complete') {
      return 'N/A'
    } else if (addresses.length === 0) {
      return 'None'
    }
    
    return addresses.join('\n')
  }

  pageUpdated(page: number) {
    page = page - 1 // zero page index for query
    if (this.onPageUpdated != null) {
      this.onPageUpdated(page)
    }
  }

  itemsPerPageUpdated(count: number) {
    if (this.onItemsPerPageUpdated != null) {
      this.onItemsPerPageUpdated(count)
    }
  }

  onAddressClicked(videoId: string, addr: string) {
    this.downloadFrame(videoId, addr)
  }
}
