


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DialogProps, ButtonProps } from '../types/general'

@Component
class FormComponent extends Vue {
  public doAction() {}
}

@Component
export default class Dialog extends Vue {
  @Prop() button!: ButtonProps
  @Prop() dialog!: DialogProps
  @Prop() contentComponent!: Function

  public show: boolean = false

  public click() {
    (this.$refs.childComponent as FormComponent).doAction()
  }
}
