










import { Component, Vue } from 'vue-property-decorator'

import Dialog from '@/components/Dialog.vue'
import SignInForm from '@/components/SignInForm.vue'

interface DialogConfig {
  button: {
    icon: string
  }
  dialog: {
    title: string
    action: string
  }
  content?: any // TODO figure out how to type this, something like https://logaretm.com/blog/2021-08-12-generically-typed-vue-components/
}

@Component({
  components: {
    Dialog
  }
})
export default class Menu extends Vue {
  public dialogs: DialogConfig[] = [
    {
      button: {
        icon: 'mdi-account-circle'
      },
      dialog: {
        title: 'Sign In',
        action: 'Sign In'
      },
      content: SignInForm
    },
    {
      button: {
        icon: 'mdi-account-circle'
      },
      dialog: {
        title: 'Sign Out',
        action: 'Sign Out'
      }
    }
  ]
}

