var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[(_vm.title !== '')?_c('h5',[_vm._v(_vm._s(_vm.title))]):_vm._e(),(_vm.searchable)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.searchLabelText,"single-line":"","variant":"outline"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"loading":_vm.isLoading,"single-select":"","loading-text":"Loading...","footer-props":{
      itemsPerPageOptions: _vm.itemsPerPage,
      showCurrentPage: true,
      showFirstLastPage: true
    },"item-key":_vm.itemKey},on:{"click:row":_vm.clicked},scopedSlots:_vm._u([{key:"item.lastSeen",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.lastSeen).toLocaleString())+" ")]}},{key:"item.lastActive",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.lastActive).toLocaleString())+" ")]}},{key:"item.dateAdded",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.dateAdded).toLocaleString())+" ")]}},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.date).toLocaleString())+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }