export function titleCase(word: string): string {
  const split = word.split('')
  split[0] = split[0].toUpperCase()
  return split.join('')
}

export const sleep = (timeoutMS: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, timeoutMS))

export function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    fallbackCopy(text)
  } else {
    navigator.clipboard.writeText(text).then(
      function () {
        console.log('Async: Copying to clipboard was successful!')
      },
      function (err) {
        console.error('Async: Could not copy text: ', err)
      }
    )
  }
}

export function fallbackCopy(text: string) {
  var textArea = document.createElement('textarea')
  textArea.value = text

  // Avoid scrolling to bottom
  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    var successful = document.execCommand('copy')
    var msg = successful ? 'successful' : 'unsuccessful'
    console.log('Fallback: Copying text command was ' + msg)
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err)
  }

  document.body.removeChild(textArea)
}

export function mergeMaps<K, V>(maps: Map<K, V>[]): Map<K, V> {
  const merged: Map<K, V> = new Map()
  maps.forEach((map: Map<K, V>) => {
    map.forEach((value: V, key: K) => {
      merged.set(key, value)
    })
  })
  return merged
}

export function ensure<T>(argument: T | undefined | null, message: string = 'This value was promised to be there.'): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message)
  }

  return argument
}
