













































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import AttributionsTable, { Header } from '@/components/AttributionsTable.vue'
import Autocomplete from '@/components/Autocomplete.vue'
import { AttributionV3, Category, SearchMap, StringMap, User } from '@/types/attributions'
import { downloadCSV } from '@/utils/download'
import { sleep } from '@/utils/general'
import { AttributionWithIds } from '@/store'
import { mapState } from 'vuex'

@Component({
  components: {
    AttributionsTable,
    Autocomplete
  },
  computed: mapState(['localAttributionMap'])
})
export default class ViewAttributions extends Vue {
  private internalHeaders: Header[] = [
    {text: 'Modify', filterable: false, sortable: false, value: 'modify', },
    {text: 'Address', filterable: true, value: 'address'},
    {text: 'Network', filterable: true, value: 'network'},
    {text: 'Attribution', filterable: true, value: 'attribution'},
    {text: 'Category', filterable: true, value: 'category'},
    {text: 'Source', filterable: true, value: 'source'},
    {text: 'Added By', filterable: true, value: 'user'},
    {text: 'Date Added', filterable: true, value: 'timeAdded'},
    {text: 'Updated', filterable: true, value: 'updated'},
    {text: 'Validated', filterable: true, value: 'validated'}
  ]
  private walletExplorerHeaders: Header[] = [
    {text: 'Address', filterable: true, value: 'address'},
    {text: 'Network', filterable: false, value: 'networks'},
    {text: 'Attribution', filterable: true, value: 'attributions'},
    {text: 'Category', filterable: true, value: 'categories'},
    {text: 'Source', filterable: true, value: 'source'}
  ]
  private geminiAdvisoryHeaders: Header[] = [
    {text: 'Address', filterable: true, value: 'address'},
    {text: 'Currency', filterable: true, value: 'currency'},
    {text: 'Source Id', filterable: true, value: 'sourceId'},
    {text: 'Group', filterable: true, value: 'group'},
    {text: 'Source Type', filterable: true, value: 'sourceType'},
    {text: 'Tier', filterable: true, value: 'tier'},
    {text: 'Has Txns', filterable: true, value: 'transactions'}
  ]
  public validatedOptions: string[] = ['TRUE', 'FALSE', 'ANY']

  public filterAddress: string | null = null
  public filterNetwork: string = ''
  public filterAttribution: AttributionWithIds | null = null
  public filterCategory: Category | string | null = null
  public filterGroup: string = ''
  public filterSourceId: string = ''
  public filterUser: string = ''
  public filterValidated: string = 'ANY'
  public dataSources = [
    {name: 'Internal', id: 'internal'},
    {name: 'Wallet Explorer', id: 'walletexplorer'},
    {name: 'Gemini Advisory', id: 'geminiadvisory'}
  ]
  public filterDataSource = 'internal'
  public filename = 'attributions'
  public internalData = false
  public modify = false
  public localAttributionMap!: Map<number, AttributionV3>

  public clearIndicator: boolean = false // value doesn't matter, Autocomplete just watches for changes

  private page: number = 0
  private itemsPerPage = 100
  public resetPage: boolean = false // value doesn't matter, AttributionsTable just watches for changes

  private estimate: boolean = true

  private illegalChars = '()[]{}.*+^$?-|'.split('')

  get headers() {
    switch (this.filterDataSource) {
      case 'internal':
        return this.internalHeaders
      case 'walletexplorer':
        return this.walletExplorerHeaders
      case 'geminiadvisory':
        return this.geminiAdvisoryHeaders
    }
    console.warn('Unknown data source:'+ this.filterDataSource)
    return []
  }

  get items(): any[] {
    return this.$store.getters.addresses
  }

  get viewCount(): number {
    return this.$store.state.addressesCount
  }

  get categories(): Category[] {
    return this.$store.state.categories
  }

  get attributions(): AttributionWithIds[] {
    return this.$store.state.attributions
  }

  get categoriesLoading(): boolean {
    return this.$store.state.categories.length === 0
  }

  get groups(): string[] {
    return this.$store.state.geminiGroups
  }

  get groupsLoading(): boolean {
    return this.$store.state.geminiGroups.length === 0
  }

  get sourceIds(): string[] {
    return this.$store.state.geminiSourceIds
  }

  get sourceIdsLoading(): boolean {
    return this.$store.state.geminiSourceIds.length === 0
  }

  get users(): string[] {
    return this.$store.state.users.map((u: User) => u.username)
  }

  get attributionsLoading(): boolean {
    return this.$store.state.attributions.length === 0
  }

  get uniqueAttributions(): string[] {
    return this.$store.state.uniqueAttributions
  }

  get networksLoading(): boolean {
    return this.$store.state.uniqueNetworks.length === 0
  }

  get uniqueNetworks(): string[] {
    return this.$store.state.uniqueNetworks
  }

  get dataLoading(): boolean {
    return !this.$store.state.viewDataLoaded
  }

  async created() {
    await Promise.all([
      this.getUsers(),
      this.getCategories(),
      this.getUniqueNetworks(),
      this.getAttributions(),
      this.getAddresses()
    ])
  }

  async getUsers() {
    await this.$store.dispatch('getUsers')
  }

  async getCategories() {
    await this.$store.dispatch('getCategories', {source: this.filterDataSource})
  }

  async getAttributions() {
    await this.$store.dispatch('getAttributions')
    await this.getUniqueNetworks()
  }

  clearUniqueAddresses() {
    this.$store.dispatch('clearUniqueAddresses')
  }

  async getUniqueAttributions(source: string) {
    await this.$store.dispatch('getUniqueAttributions', {source: source})
  }

  async getUniqueNetworks() {
    if (this.filterAttribution) {
      await this.$store.dispatch('getUniqueNetworksForAttribution', { attributionId: this.filterAttribution.attributionId })
    } else {
      await this.$store.dispatch('getUniqueNetworks', {source: this.filterDataSource})
    }
  }

  async getGeminiGroups() {
    await this.$store.dispatch('getGeminiGroups')
  }

  async getGeminiSourceIds() {
    await this.$store.dispatch('getGeminiSourceIds')
  }

  handleInputChange() {
    if (this.filterNetwork == null) {
      this.filterNetwork = ''
    }
  }

  async resetSearch() {
    this.resetFilters()
    this.filterDataSource = 'internal'
    await this.getAddresses()
  }

  addressChanged(result: string) {
    this.filterAddress = result
  }

  resetFilters() {
    this.filterAddress = null
    this.clearIndicator = !this.clearIndicator // this will cause the autocomplete value to clear
    this.filterNetwork = ''
    this.filterAttribution = null
    this.filterCategory = null
    this.filterUser = ''
    this.estimate = true
  }

  @Watch('filterDataSource')
  async dataSourceChanged() {
    this.resetFilters()
    this.clearAddresses()
    await Promise.all([
      this.getAddresses(),
      this.getUniqueNetworks(),
      this.getCategories()
    ])
    if (this.filterDataSource === 'internal' || this.filterDataSource === 'walletexplorer') {
      await this.getUniqueAttributions('attribution')
    }
    if (this.filterDataSource === 'walletexplorer') {
      this.clearUniqueAddresses()
    }
    if (this.filterDataSource === 'geminiadvisory') {
      await Promise.all([
        this.getGeminiGroups(),
        this.getGeminiSourceIds()
      ])
    }
  }
  
  async clearAddresses() {
    await this.$store.dispatch('clearAddresses')
  }

  async getAddresses() {
    this.resetPage = !this.resetPage
    await sleep(500) // give the page some time to change based on the watcher in the child component
    this.clearAddresses()
    const query = this.composeQuery()
    await Promise.all([
      this.$store.dispatch('getAddresses', {page: this.page, count: this.itemsPerPage, query}),
      this.$store.dispatch('getAddressesCount', {query, estimate: this.estimate}),
      this.getUniqueNetworks()
    ])
  }

  escapeRegexString(str: string) {
    this.illegalChars.forEach(c => {
      str = str.replaceAll(c, `\\${c}`)
    })
    return str.trim()
  }


  replaceAlphabetCharacters(str: string): string {
    // const pattern = /[a-zA-Z]/g
    // const replacedString = str.replace(pattern, (match) => `[${match.toUpperCase()}${match.toLowerCase()}]`)
    return `^${str}.*$`
  }

  composeAttributionQuery(): StringMap {
    const query: StringMap = {}
    return query
  }

  composeQuery(attribution: boolean = false): SearchMap {
    const query: SearchMap = {}
    this.estimate = true
    if (this.filterAddress != null && this.filterAddress.trim() !== '') {
      query['address'] = `/${this.replaceAlphabetCharacters(this.filterAddress)}/`
      this.estimate = false
    }
    if (this.filterNetwork.trim() !== '') {
      query['network'] = this.filterNetwork.trim()
      this.estimate = false
    }
    if (this.filterAttribution !== null) {
      query['attributionId'] = this.filterAttribution.ids
      this.estimate = false
    }
    if (this.filterCategory !== null && this.filterCategory !== '') {
      const cat = (typeof this.filterCategory === 'string') ? this.filterCategory : `${this.filterCategory.id}`
      query['categoryId'] = cat
      this.estimate = false
    }
    if (this.filterUser !== null && this.filterUser !== '') {
      query['user'] = `${this.filterUser}`
      this.estimate = false
    }
    if (!attribution && this.filterDataSource !== '') {
      query['dataSource'] = `${this.filterDataSource}`
    }
    if (this.filterGroup !== null && this.filterGroup !== '') {
      query['group'] = `/${this.escapeRegexString(this.filterGroup)}/i`
      this.estimate = false
    }
    if (this.filterSourceId !== null && this.filterSourceId !== '') {
      query['sourceId'] = `/${this.escapeRegexString(this.filterSourceId)}/i`
    }
    if (this.filterValidated !== null && this.filterValidated !== 'ANY') {
      const val = (this.filterValidated === 'TRUE') ? 'true' : 'false'
      query['validated'] = val
      this.estimate = false
    }
    return query
  }

  async pageUpdated(page: number) {
    this.page = page
    const query = this.composeQuery()
    this.clearAddresses()
    await this.$store.dispatch('getAddresses', {page: this.page, count: this.itemsPerPage, query})
  }

  itemsPerPageUpdated(count: number) {
    this.itemsPerPage = count
  }

  async itemUpdated() {
    await sleep(500)
    await this.getAddresses()
  }

  async itemDeleted() {
    await sleep(500)
    await this.getAddresses()
  }

  async itemAdded() {
    await sleep(500)
    await this.getAddresses()
  }
  
  downloadFile() {
    downloadCSV(this.items, this.filename, this.internalData)
  }
}
