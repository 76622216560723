
















































































































































































import { Component, Vue, Watch, ProvideReactive } from 'vue-property-decorator'
import { sleep } from '@splunkdlt/async-tasks'
import { Header } from '@/components/AttributionsTable.vue'
import { mapState } from 'vuex'
import { AttributionV3, Category } from '../types/attributions'
import { chunkArray } from '@/utils/transform'
import { ApiResponse, InsertionState, InsertionStateWithId, UploadState, UploadStateWithId, ValidationState, ValidationStateWithId } from '@/utils/api'

interface StateWithId {
  jobId: string
  timestamp: number
  upload?: UploadStateWithId
  validation?: ValidationStateWithId
  insertion?: InsertionStateWithId
}

@Component({
  components: {},
  computed: {
    ...mapState(['categoryMap', 'uploadStates', 'validationStates', 'insertionStates', 'statesUpdated'])
  }
})
export default class AddAttributions extends Vue {
  private categoryMap!: Map<number, Category>
  private uploadStates!: Map<string, UploadState>
  private validationStates!: Map<string, ValidationState>
  private insertionStates!: Map<string, InsertionState>
  private statesUpdated!: number

  private headers: Header[] = [
    {text: 'Address', filterable: true, value: 'address', validate: true},
    {text: 'Network', filterable: true, value: 'network', validate: true},
    {text: 'Attribution', filterable: true, value: 'attribution', validate: true},
    {text: 'Category', filterable: true, value: 'category', validate: true},
    {text: 'Source', filterable: true, value: 'source', validate: true},
    {text: 'Note', filterable: true, value: 'note', optional: true},
    {text: 'User', filterable: true, value: 'user', optional: true},
    {text: 'TimeAdded', filterable: true, value: 'timeAdded', optional: true}
  ]

  public addressHeaders: Header[] = [
    {text: 'Address', value: 'address'}
  ]
  public categoryHeaders: Header[] = [
    { text: 'Attribution', value: 'attribution' },
    { text: 'ID (DB)', value: 'originalCategoryId' },
    { text: 'Name (DB)', value: 'originalCategory' },
    { text: 'ID (File)', value: 'conflictingCategoryId' },
    { text: 'Name (File)', value: 'conflictingCategory' },
    { text: 'Count', value: 'count' }
  ]
  public similarAttributionsHeaders: Header[] = [
    {text: 'In Database', value: 'field'},
    {text: 'In File', value: 'isSimilarTo'},
    {text: 'Count', value: 'count'}
  ]

  public headersDisplayed: Header[] = this.headers.slice(0,this.headers.length-1)
  public headerMap: Map<string, Header> = this.headers.reduce((merged, item) => {
      const h = new Map<string, Header>()
      h.set(item.value, item)
      return new Map<string, Header>([...merged, ...h])
    }, new Map<string, Header>())
  public files: File[] | null = []
  public filenames: string[] = []

  private attributionNameToAttributionMap: Map<string, AttributionV3> | null = null
  public dialog: boolean = false
  private maxFileSize = 1024 * 1024 * 1024 * 1 // 1GB
  public fileUploadRules = [
    (files: File[] | null) => files != null && files.every(file => file.size <= this.maxFileSize) || 'File size should be less than 1 GB'
  ]

  public uploading = false
  public totalFileSize = 0
  public totalUploadProgress = 0
  private completedFileUploadSize = 0
  private currentFileSize = 0
  public selectedJobId: string[] = []
  private currentFile = ''
  
  public validating: boolean = false
  public validated = false
  public validationState: ValidationState | null = null

  private stateMap: { [key: string]: {
      upload?: UploadStateWithId
      validation?: ValidationStateWithId
      insertion?: InsertionStateWithId
    }} = {}

  public warned = false
  public adding = false
  public added = false

  @ProvideReactive() public statesList: StateWithId[] = []

  getUploadStateList(): UploadStateWithId[] {
    const uploadStates: Map<string, UploadState> = this.$store.state.uploadStates
    if (this.uploadStates != null) {
      return Array.from(uploadStates.entries()).map(([ jobId, status ]) => ({ jobId, ...status }))
    }
    return []
  }

  getValidationStateList(): ValidationStateWithId[] {
    const validationStates: Map<string, ValidationState> = this.$store.state.validationStates
    if (this.validationStates != null) {
      return Array.from(validationStates.entries()).map(([ jobId, status ]) => ({ jobId, ...status }))
    }
    return []
  }

  getInsertionStateList(): InsertionStateWithId[] {
    const insertionStates: Map<string, InsertionState> = this.$store.state.insertionStates
    if (this.insertionStates != null) {
      return Array.from(insertionStates.entries()).map(([ jobId, status ]) => ({ jobId, ...status }))
    }
    return []
  }

  getValidationProgress(index: number): number {
    const state = this.statesList[index]
    if (state != null && state.validation != null) {
      const { files, validatedFiles } = state.validation
      if (files.length > 0 && validatedFiles.length > 0) {
        return Math.round((validatedFiles.length / files.length) * 100)
      }
    }
    return 0
  }

  getInsertionProgress(index: number): number {
    const state = this.statesList[index]
    if (state != null && state.insertion != null) {
      const { files, insertedFiles } = state.insertion
      if (files.length > 0 && insertedFiles.length > 0) {
        return Math.round((insertedFiles.length / files.length) * 100)
      }
    }
    return 0
  }

  get uploadButtonDisabled() {
    return this.uploading || this.files == null || this.files.length === 0 || this.validating
  }

  get validateButtonDisabled() {
    return this.uploading || this.selectedJobId == null || this.selectedJobId.length === 0 || this.validating
  }

  get addButtonDisabled() {
    return this.uploading || !this.validated || this.adding || this.added
  }

  get existingAddresses() {
    const { validationState } = this
    if (validationState != null && validationState.results != null) {
      const { existingAddresses } = validationState.results
      if (existingAddresses != null) {
        return existingAddresses.map(address => ({ address }))
      }
    }
    return []
  }

  get similarAttributions() {
    const { validationState } = this
    if (validationState != null && validationState.results != null) {
      const { similarlyNamedAttributions } = validationState.results
      if (similarlyNamedAttributions != null) {
        return similarlyNamedAttributions.map(({ field, isSimilarTo, count}) => ({ field, isSimilarTo: isSimilarTo.join(', '), count }))
      }
    }
    return []
  }

  get conflictingCategories() {
    const { validationState } = this
    if (validationState != null && validationState.results != null) {
      const { conflictingCategories } = validationState.results
      if (conflictingCategories != null) {
        return conflictingCategories.map(({ attribution, originalCategoryId, conflictingCategoryId, count}) => ({
          attribution,
          originalCategoryId,
          originalCategory: this.getCategory(originalCategoryId),
          conflictingCategoryId,
          conflictingCategory: this.getCategory(conflictingCategoryId),
          count
        }))
      }
    }
    return []
  }

  getCategory(id: number): string {
    const category = this.categoryMap.get(id)
    if (category != null) {
      return category.name
    }
    return '?'
  }

  selectJob(id: string) {
    // all switches use the selectedJobId as a model and the model is updated before this function is called,
    // so at this point, there could be more than one switch turned "on". we only want one switch "on" at a time.
    if (this.selectedJobId.length > 1 && this.selectedJobId.includes(id)) {
      // at this point, there are multiple switches turned on, and we want to turn off
      // the one that was not clicked. we accomplish that by doing the opposite:
      // filter out everything but the id of the switch clicked.
      this.selectedJobId = this.selectedJobId.filter(jobId => jobId === id)
    } else if (this.selectedJobId.length === 0) {
      // no job selected
      if ((this.files == null || this.files.length === 0) && this.filenames.length === 0 && !this.validated && this.validationState == null) {
        // first load and only one job in list, select it
        this.selectedJobId = [id]
      } else {
        // reset vars when nothing is selected
        this.files = []
        this.filenames = []
        this.validated = false
        this.validationState = null
      }
      
    } else {
      // no job was selected and now there is one.
      this.selectedJobId = [id]
    }
    // handle setting state related values
    // this will enable/disable the "add" button and show the validation results.
    if (this.selectedJobId.length > 0 && this.stateMap[id] != null) {
      const { upload, validation, insertion } = this.stateMap[id]
      // set upload values
      if (upload != null && upload.files.length > 0) {
        this.filenames = upload.files
      }
      // set validation vales
      if (validation != null && validation.files.length === validation.validatedFiles.length) {
        this.warned = false
        this.validated = true
        this.validationState = validation
        this.filenames = validation.files
        const { results } = validation
        if (results != null) {
          const { conflictingCategories, similarlyNamedAttributions } = results
          const hasConflicts = conflictingCategories != null && conflictingCategories.length > 0
          const hasSimilarNames = similarlyNamedAttributions != null && similarlyNamedAttributions.length > 0
          if (hasConflicts || hasSimilarNames) {
            const conflicts = conflictingCategories != null && conflictingCategories.length > 0 ? ` ${conflictingCategories.length} category conflicts.` : ''
            const similarNames = similarlyNamedAttributions != null && similarlyNamedAttributions.length > 0 ? ` ${similarlyNamedAttributions.length} similarly named attributions.` : ''
            const message = `Validation complete with issues.${conflicts}${similarNames}`
            this.$store.dispatch('showMessageDialog', { message , button: 'Ok', show: true })
          } else {
            this.$store.dispatch('showMessageDialog', { message: 'Validation complete without issues.', button: 'Ok', show: true })
          }
        }
      } else {
        this.validated = false
      }
      // set insertion values
      if (insertion != null && insertion.files.length === insertion.insertedFiles.length) {
        this.added = true
        this.filenames = insertion.files
      } else {
        this.added = false
      }
    } else {
      this.validated = false
    }
  }

  async refreshMap() {
    this.attributionNameToAttributionMap = this.$store.state.attributionNameToAttributionMap
  }

  async init() {
    await Promise.all([
      await this.getCategories(),
      await this.getJobStates()
    ])
  }

  async getCategories() {
    await this.$store.dispatch('getCategories', {source: 'internal'})
  }

  @Watch('statesUpdated', { deep: true })
  setJobStates() {
    this.stateMap = {}
    let timestamp = 0
    for (const {jobId, ...state} of this.getUploadStateList()) {
      if (state.lastUpdated > timestamp) {
        timestamp = state.lastUpdated
      }
      if (this.stateMap[jobId] == null) {
        this.stateMap[jobId] = { upload: { jobId, ...state } }
      } else {
        this.stateMap[jobId].upload = { jobId, ...state }
      }
    }
    for (const {jobId, ...state} of this.getValidationStateList()) {
      if (state.lastUpdated > timestamp) {
        timestamp = state.lastUpdated
      }
      if (this.stateMap[jobId] == null) {
        this.stateMap[jobId] = { validation: { jobId, ...state } }
      } else {
        this.stateMap[jobId].validation = { jobId, ...state }
      }
    }
    for (const {jobId, ...state} of this.getInsertionStateList()) {
      if (state.lastUpdated > timestamp) {
        timestamp = state.lastUpdated
      }
      if (this.stateMap[jobId] == null) {
        this.stateMap[jobId] = { insertion: { jobId, ...state } }
      } else {
        this.stateMap[jobId].insertion = { jobId, ...state }
      }
    }
    console.log(JSON.parse(JSON.stringify(this.stateMap)))
    this.statesList = Object.keys(this.stateMap).map((jobId) => ({
      jobId,
      timestamp,
      ...this.stateMap[jobId]
    }))
    if (this.statesList.length === 1) {
      console.log(`selecting ${this.statesList[0].jobId}`)
      this.selectJob(this.statesList[0].jobId)
    }
  }

  async getJobStates() {
    const states = await this.$store.dispatch('listAllValidationJobs')
    // this.setJobStates()
    // if (this.statesList.length === 1) {
    //   this.selectJob(this.statesList[0].jobId)
    // }
  }

  async deleteJob(jobId: string) {
    await this.$store.dispatch('deleteValidationJob', jobId)
    await this.getJobStates()
  }

  // async getAttributions() {
  //   await this.$store.dispatch('getAttributions', {page: 0, count: 10000, query: {}})
  // }

  public async uploadFiles() {
    const { files } = this
    if (files != null && files.length > 0) {
      this.filenames = files.map(f => f.name)
      this.uploading = true
      this.totalFileSize = files.reduce((total, file) => total + file.size, 0)
      // create map of files to track progress
      const fileMap = files.reduce((map, file) => {
        map.set(file.name, file.size)
        return map
      }, new Map<string, number>())
      const parallelUploads = 1 // if you want to change this then the progress stuff needs to be updated to handle multiple files
      const chunks = chunkArray(files, parallelUploads)
      for (const chunk of chunks) {
        const results = await Promise.all(chunk.map(file => {
          const data = new FormData()
          data.append('file', file)
          data.append('filename', file.name)
          if (this.selectedJobId != null && this.selectedJobId.length > 0) {
            data.append('jobId', this.selectedJobId[0])
          }
          this.currentFile = file.name
          this.currentFileSize = file.size
          return this.$store.dispatch('uploadFile', {data}) as Promise<ApiResponse<{ jobId: string, filename: string}>>
        }))
        // update progress for each file in the chunk
        results.forEach(({ success, data }) => {
          if (success && data != null) {
            const { jobId, filename } = data
            this.selectJob(jobId)
            const fileSize = fileMap.get(filename)
            if (fileSize != null) {
              this.completedFileUploadSize += fileSize / this.totalFileSize * 100
            }
            fileMap.delete(filename)
          } else {
            console.error(`error uploading file`)
          }
        })
        // trigger processing
        await this.getJobStates()
      }
      this.uploading = false
    }
  }

  @Watch('uploadUpdate')
  private updateFileProgress() {
    const uploadId = `${this.selectedJobId}-${this.currentFile}`
    const progress = (<Map<string, number>>this.$store.state.uploadProgress).get(uploadId)
    if (progress != null) {
      const totalUploadSize = this.completedFileUploadSize + this.currentFileSize * progress
      this.totalUploadProgress = totalUploadSize / this.totalFileSize * 100
      console.log(`file upload progress ${this.totalUploadProgress}`)
    } else {
      console.error(`no upload progress for ${uploadId}`)
    }
  }

  public async validateUploadedFiles() {
    this.warned = false
    this.validated = false
    this.validating = true
    const { selectedJobId: jobId, filenames } = this
    if (filenames.length === 0) {
      console.error(`no files to validate`)
      return
    }
    if (jobId != null && jobId.length > 0) {
      const id = jobId[0]
      await this.$store.dispatch('validateFiles', { jobId: id, filenames })
      this.validationStateLoop(id, 5000)
    }
  }

  public async getValidationState(jobId: string): Promise<ValidationState> {
    return this.$store.dispatch('getValidationState', jobId)
  }

  public async validationStateLoop(jobId: string, intervalMs: number): Promise<void> {
    while (this.validating) {
      const validationState = await this.getValidationState(jobId)
      await this.getJobStates()
      this.setJobStates()
      this.validationState = validationState
      if (validationState.files.length === validationState.validatedFiles.length) {
        this.validated = true
        this.validating = false
        const { results } = validationState
        if (results != null) {
          const { conflictingCategories, similarlyNamedAttributions } = results
          const hasConflicts = conflictingCategories != null && conflictingCategories.length > 0
          const hasSimilarNames = similarlyNamedAttributions != null && similarlyNamedAttributions.length > 0
          if (hasConflicts || hasSimilarNames) {
            const conflicts = conflictingCategories != null && conflictingCategories.length > 0 ? ` ${conflictingCategories.length} category conflicts.` : ''
            const similarNames = similarlyNamedAttributions != null && similarlyNamedAttributions.length > 0 ? ` ${similarlyNamedAttributions.length} similarly named attributions.` : ''
            const message = `Validation complete with issues.${conflicts}${similarNames}`
            this.$store.dispatch('showMessageDialog', { message , button: 'Ok', show: true })
          } else {
            this.$store.dispatch('showMessageDialog', { message: 'Validation complete without issues.', button: 'Ok', show: true })
          }
        }
      }
      await sleep(intervalMs)
    }
  }

  public async addUploadedFiles() {
    if (this.validationState && this.validationState.results != null) {
      const { results } = this.validationState
      const { conflictingCategories, similarlyNamedAttributions } = results
      if (
        ((conflictingCategories != null && conflictingCategories.length > 0) ||
        (similarlyNamedAttributions != null && similarlyNamedAttributions.length > 0)) &&
        !this.warned
      ) {
        this.warned = true
        this.$store.dispatch('showMessageDialog', { message: 'Warning! There are validation issues. If you still want to continue, click "OK" and then click the "Add" button again.', button: 'Ok', show: true })
        return
      }
      this.added = false
      this.adding = true
      const { selectedJobId: jobId, filenames } = this
      if (filenames.length === 0) {
        console.error(`no files to add`)
        return
      }
      if (jobId != null && jobId.length > 0) {
        const id = jobId[0]
        await this.$store.dispatch('insertAttributionsFiles', { jobId: id, filenames })
        this.insertionStateLoop(id, 5000)
      }
    } else {
      this.$store.dispatch('showMessageDialog', { message: 'No validation results', button: 'Ok', show: true })
    }
  }

  public async getInsertionState(jobId: string): Promise<InsertionState> {
    return this.$store.dispatch('getInsertionState', jobId)
  }

  public async insertionStateLoop(jobId: string, intervalMs: number): Promise<void> {
    while (this.adding) {
      await this.getJobStates()
      this.setJobStates()
      const insertionState = await this.getInsertionState(jobId)
      if (insertionState != null && insertionState.files.length === insertionState.insertedFiles.length) {
        this.added = true
        this.adding = false
      }
      await sleep(intervalMs)
    }
  }

  async created() {
    await this.init()
  } 
}
