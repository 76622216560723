



























import { DatasetResult } from '@/store'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  computed: mapState(['autocompleteResults', 'autocompleteSearches'])
})
export default class Autocomplete extends Vue {
  @Prop() onChange!: (value: string) => void
  @Prop() onResult!: (result: string) => void
  @Prop() onClear!: () => void
  @Prop() watchToClear!: boolean
  public value: string = ''
  public selected: string | null = null
  public autocompleteResults!: DatasetResult[]
  loading: boolean = false
  private clicked = false

  @Watch('value')
  public async autocomplete() {
    if (!this.clicked) {
      const { value } = this
      if (value == null || value.length === 0) {
        // cleared.
        await this.$store.dispatch('clearAutocomplete')
      }
      if (value != null && value.trim() !== '') {
        this.loading = true
        await this.$store.dispatch('executeAutocomplete', { dataset: 'all', value, network: '*' })
        this.loading = false
      }
    } else {
      this.$store.dispatch('clearAutocomplete')
      this.clicked = false
    }
  }

  itemClicked(item: DatasetResult) {
    this.clicked = true
    if (item != null && item.result != null) {
      if (this.value !== item.result) {
        this.value = item.result
        if (this.onResult != null) {
          this.onResult(this.value)
        }
      } else {
        (this.$refs.autocomplete as HTMLElement).blur()
      }
    }
  }

  @Watch('watchToClear')
  public clear() {
    this.value = ''
    this.loading = false
    if (this.onClear != null) {
      this.onClear()
    }
  }
}
