










































































































import { Component, Vue } from 'vue-property-decorator'
import { customAlphabet } from 'nanoid/async'
import Table, { Header } from '@/components/Table.vue'
import { ApiKey } from '@/store'
import { User } from '@/types/attributions'

interface SignupCode {
  code: string
  used: boolean
  date: string
}

interface GenParams {
  alphabet: string
  length: number
}

@Component({
  components: {
    Table
  }
})
export default class Accounts extends Vue {
  public apiKeyHeaders: Header[] = [
    {text: 'Key', filterable: true, value: 'key'},
    {text: 'User', filterable: true, value: 'username'},
    {text: 'Active', filterable: true, value: 'active'},
    {text: 'Added', filterable: true, value: 'dateAdded'},
    {text: 'Last Active', filterable: true, value: 'lastActive'}
  ]
  public signupCodeHeaders: Header[] = [
    {text: 'Code', filterable: false, value: 'code'},
    {text: 'Used', filterable: false, value: 'used'},
    {text: 'Date', filterable: true, value: 'date'}
  ]
  public userHeaders: Header[] = [
    {text: 'Username', filterable: false, value: 'username'},
    {text: 'Email', filterable: false, value: 'email'},
    {text: 'Active', filterable: true, value: 'active'},
    {text: 'Last Seen', filterable: true, value: 'lastSeen'}
  ]

  public showPass: boolean = false
  public rules = {
    required: (value: string) => !!value || 'Required',
    min: (value: string) => value.length >= 8 || 'Min 8 characters',
    email: (value: string) => {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || 'Invalid e-mail.'
    }
  }
  public username: string = ''
  public password: string = ''
  public email: string = ''
  public signupCode: string = ''
  private pwdGenParams: GenParams = {
    alphabet: '1234567890abcdefghijklmnopqrstuvwxyz-_!*&$#@',
    length: 20
  }

  async created() {
    await Promise.all([
      this.getSignupCodes(),
      this.getApiKeys(),
      this.getUsers()
    ])
  }

  get apiKeysList(): ApiKey[] {
    return this.$store.state.apiKeys
  }

  get signupCodesList(): SignupCode[] {
    return this.$store.state.signupCodes
  }

  get usersList(): User[] {
    return this.$store.state.users
  }

  public async getUsers() {
    await this.$store.dispatch('getUsers')
  }

  public async getApiKeys() {
    await this.$store.dispatch('getApiKeys')
  }

  public async genApiKey() {
    await this.$store.dispatch('genApiKey')
  }

  public async getSignupCodes() {
    await this.$store.dispatch('getSignupCodes')
  }

  public async genSignupCodes() {
    await this.$store.dispatch('genSignupCodes')
  }

  public async addUser() {
    await this.$store.dispatch('signUp', {
      username: this.username,
      password: this.password,
      email: this.email,
      code: this.signupCode
    })
  }

  public async genPassword() {
    const nanoid = customAlphabet(this.pwdGenParams.alphabet, this.pwdGenParams.length)
    const pwd = await nanoid()
    this.password = pwd
  }
  
}
