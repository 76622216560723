import { jsonToCSV, youtubeJsonToCSV } from './transform'

function download(data: string, filename: string) {
  const blob = new Blob([data], { type: 'text/csv' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = filename
  link.click()
  URL.revokeObjectURL(link.href)
}

export function downloadCSV(data: any, filename: string, internalData: boolean) {
  const csv = jsonToCSV(data, internalData)
  download(csv, filename)
}

export function downloadYoutubeCSV(data: any, filename: string) {
  const csv = youtubeJsonToCSV(data)
  download(csv, filename)
}

export function displayPng(data: any) {
  const blob = b64toBlob(data, 'image/png')
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.target = '_blank'
  link.click()
  URL.revokeObjectURL(link.href)
}

export function b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
  const byteCharacters = atob(b64Data)
  const byteArrays: Uint8Array[] = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
