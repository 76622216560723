







































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { AttributionV2, AttributionV3, Category } from '@/types/attributions'
import { EditAddressRequest } from '@/utils/api'
import { AttributionWithIds } from '@/store'
import { mapState } from 'vuex'

interface EditedAttribution {
  address: string
  network: string
  attributionId?: number
  categoryId?: number
  source: string
  user: string
  timeAdded?: Date
  note?: string
  validated?: boolean
  attribution: string
}

@Component({
  computed: mapState(['categories', 'attributions', 'uniqueNetworks', 'attributionNameToAttributionMap', 'localAttributionMap'])
})
export default class AddEditAttributionDialog extends Vue {
  @Prop() title!: string
  @Prop() mode!: string
  private oldNetwork!: string
  private oldSource!: string
  @Prop() onItemAdded!: Function
  @Prop() onItemUpdated!: Function
  public editedAddress: EditedAttribution | null = null
  public show: boolean = false

  public categories!: Category[]
  public attributions!: AttributionWithIds[]
  public uniqueNetworks!: string[]
  public attributionNameToAttributionMap!: Map<string, AttributionV3> | null
  public localAttributionMap!: Map<number, AttributionV3>

  open({ user, item }: { user?: string; item?: AttributionV2 }) {
    if (item != null) {
      const category = this.categories.find(c => c.id === item.categoryId)
      let categoryId
      if (category == null) {
        categoryId = this.categories[0].id
      } else {
        ({ id: categoryId } = category)
      }

      let attribution: string = ''
      if (item.attributionId != null) {
        const attributionItem = this.localAttributionMap.get(item.attributionId)
        if (attributionItem != null) {
          ({ name: attribution } = attributionItem)
        }
      }
      this.editedAddress = { ...item, categoryId, attribution }
      this.oldNetwork = item.network
      this.oldSource = item.source
    } else if (user != null) {
      this.editedAddress = {
        address: '',
        network: '',
        source: '',
        user,
        attribution: '',
        note: '',
        validated: false
      }
    }
    this.show = true
  }

  async editItemConfirm() {
    if (this.editedAddress != null && this.attributionNameToAttributionMap != null) {
      if (this.editedAddress.attribution !== '') {
        const attributionId = await this.getOrAddAttributionId()
        if (attributionId != null) {
          const {
            address,
            network,
            source,
            timeAdded,
            user,
            validated,
            note
          } = this.editedAddress
          const edited: EditAddressRequest = {
            address,
            attributionId,
            network,
            source,
            timeAdded,
            user,
            oldNetwork: this.oldNetwork,
            oldSource: this.oldSource,
            validated: !!validated,
            note
          }
          await this.$store.dispatch('editAddress', {
            edited,
          })
          this.close()
          if (this.onItemUpdated != null) {
            this.onItemUpdated()
          }
        }
      }
    } else {
      console.warn('editItemConfirm - this.attributionNameToAttributionMap not instantiated yet.')
    }
    this.close()
  }

  async addItemConfirm() {
    if (this.editedAddress != null && this.editedAddress.attribution !== '' && this.editedAddress.categoryId != null) {
      const attributionId = await this.getOrAddAttributionId()
      if (attributionId != null) {
        const address: AttributionV2 = {
          address: this.editedAddress.address,
          categoryId: this.editedAddress.categoryId,
          attributionId,
          source: this.editedAddress.source,
          network: this.editedAddress.network,
          user: this.editedAddress.user,
          note: this.editedAddress.note ?? '',
          validated: !!this.editedAddress.validated,
          timeAdded: undefined as any // only putting this field here for typing, it'll get added by Mongo
        }
        this.$store.dispatch('sendAddresses', {       
          data: [address],
          filename: '' })
        this.close()
        if (this.onItemAdded != null) {
          this.onItemAdded()
        }
      }
    }
    this.close()
  }

  private async getOrAddAttributionId() {
    if (this.editedAddress != null) {
      const name: string = this.editedAddress.attribution
      let attributionId: number | undefined = (
        this.attributionNameToAttributionMap != null && this.attributionNameToAttributionMap.has(name)
        ) ? this.attributionNameToAttributionMap.get(name)!.attributionId ?? undefined
        : undefined
      if (attributionId == null) {
        await this.$store.dispatch('addAttribution', {
          attribution: {
            name,
            categoryId: this.editedAddress.categoryId,
            user: this.editedAddress.user
          } as AttributionV3
        })
        attributionId = (
          this.attributionNameToAttributionMap != null && this.attributionNameToAttributionMap.has(name)
          ) ? this.attributionNameToAttributionMap.get(name)!.attributionId ?? undefined
          : undefined
      }
      return attributionId
    }
    return undefined
  }

  close() {
    this.editedAddress = null
    this.show = false
  }
}
