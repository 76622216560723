import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import ViewAttributions from '@/views/ViewAttributions.vue'
import AddAttributions from '@/views/AddAttributions.vue'
import YouTubeScans from '@/views/YouTubeScans.vue'
import Accounts from '@/views/Accounts.vue'
import Admin from '@/views/Admin.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/viewattributions',
    name: 'View Attributions',
    component: ViewAttributions
  },
  {
    path: '/addattributions',
    name: 'Add Attributions',
    component: AddAttributions
  },
  {
    path: '/youtubescans',
    name: 'YouTube Scans',
    component: YouTubeScans
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
